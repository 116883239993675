export const stakeOpt = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
  {
    id: 2,
    optImg: "eth.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
  {
    id: 3,
    optImg: "usdt.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
  {
    id: 5,
    optImg: "eth.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
  {
    id: 6,
    optImg: "usdt.png",
    optName: "BTC",
    optSub: "Bitcoin",
    optPercentage: "25%",
    optTerm: "Flexible",
    optAction: "Stake",
  },
];

export const stakeHistory = [
  {
    id: 1,
    pacImg: "btc.png",
    pacName: "BTC",
    pacSub: "Bitcoin",
    amount: 69195.4,
    apr: "60%",
    type: "Fixed",
    stakeDate: "365 days",
    stakeEndDate: "20/07/2024",
    nextClaimDate: "20/07/24",
    interest: "0.120000 ETH",
    intPerCycle: "0.120000 ETH",
  },
  {
    id: 2,
    pacImg: "btc.png",
    pacName: "BTC",
    pacSub: "Bitcoin",
    amount: 69195.4,
    apr: "60%",
    type: "Fixed",
    stakeDate: "365 days",
    stakeEndDate: "20/07/2024",
    nextClaimDate: "20/07/24",
    interest: "0.120000 ETH",
    intPerCycle: "0.120000 ETH",
  },
  {
    id: 3,
    pacImg: "btc.png",
    pacName: "BTC",
    pacSub: "Bitcoin",
    amount: 69195.4,
    apr: "60%",
    type: "Fixed",
    stakeDate: "365 days",
    stakeEndDate: "20/07/2024",
    nextClaimDate: "20/07/24",
    interest: "0.120000 ETH",
    intPerCycle: "0.120000 ETH",
  },
  {
    id: 4,
    pacImg: "btc.png",
    pacName: "BTC",
    pacSub: "Bitcoin",
    amount: 69195.4,
    apr: "60%",
    type: "Fixed",
    stakeDate: "365 days",
    stakeEndDate: "20/07/2024",
    nextClaimDate: "20/07/24",
    interest: "0.120000 ETH",
    intPerCycle: "0.120000 ETH",
  },
];

export const accordianData = [
  {
    id: 1,
    questions: "What is Staking?",
    answers:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex est sed deserunt hic veniam, vitae asperiores iure. Dolorem, quas minima!",
  },
  {
    id: 2,
    questions: "What is Staking?",
    answers:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex est sed deserunt hic veniam, vitae asperiores iure. Dolorem, quas minima!",
  },
  {
    id: 3,
    questions: "What is Staking?",
    answers:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex est sed deserunt hic veniam, vitae asperiores iure. Dolorem, quas minima!",
  },
  {
    id: 4,
    questions: "What is Staking?",
    answers:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex est sed deserunt hic veniam, vitae asperiores iure. Dolorem, quas minima!",
  },
];

export const selectDate = [
  {
    id: 1,
    day: "30 D",
  },
  {
    id: 2,
    day: "90 D",
  },
  {
    id: 3,
    day: "180 D",
  },
  {
    id: 4,
    day: "365 D",
  },
];

export const assetList = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 2,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 3,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 6,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 7,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
  {
    id: 8,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: 532.32,
    total: "0213114",
    action: "Deposit",
  },
];

export const advertiserDetails = [
  {
    id: 1,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Buy",
  },
  {
    id: 2,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Buy",
  },
  {
    id: 3,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Buy",
  },
  {
    id: 4,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Buy",
  },
  {
    id: 5,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Buy",
  },
];

export const advertiserDetailSell = [
  {
    id: 1,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Sell",
  },
  {
    id: 2,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Sell",
  },
  {
    id: 3,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Sell",
  },
  {
    id: 4,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Sell",
  },
  {
    id: 5,
    optImg: "j.png",
    optName: "Jane Cooper",
    optSub: "221 Volume | 97.00% Transaction rate",
    price: 1500,
    amountBtc: "0.025 BTC",
    limit: "₹5000 - ₹6000",
    paymentMethod: "Bank Transfer",
    trade: "Sell",
  },
];

export const historyList = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
  {
    id: 2,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
  {
    id: 3,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
  {
    id: 6,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "0x386754ca7...",
    total: "19/07/2024 08:42 PM",
    action: "Success",
  },
];

export const marketTable = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
  {
    id: 2,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
  {
    id: 3,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
  {
    id: 6,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "/USDT",
    fiatAccount: 65195.4,
    cryptoAccount: `$67,411.99`,
    total: "612.61M",
    action: "+0.66%",
  },
];

export const rewardTable = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Deposit Bonus",
    total: "19/07/2024 08:42 PM",
  },
  {
    id: 2,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Withdrawal Bonus",
    total: "19/07/2024 08:42 PM",
  },
  {
    id: 3,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Deposit Bonus",
    total: "19/07/2024 08:42 PM",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Withdrawal Bonus",
    total: "19/07/2024 08:42 PM",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Deposit Bonus",
    total: "19/07/2024 08:42 PM",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    fiatAccount: 65195.4,
    cryptoAccount: "Withdrawal Bonus",
    total: "19/07/2024 08:42 PM",
  },
];

export const activeOrderData = [
  {
    id: 1,
    activeDate: "28-05-2024 12:26:44",
    activePair: "BNB/USDT",
    activePrice: "601.80000",
    activeSide: "Buy",
    activeOrder: "Limit",
    activeAmount: "0.3115648",
    activeTotal: "187.25555475",
  },
  {
    id: 2,
    activeDate: "28-05-2024 12:26:44",
    activePair: "BNB/USDT",
    activePrice: "601.80000",
    activeSide: "Sell",
    activeOrder: "Limit",
    activeAmount: "0.3115648",
    activeTotal: "187.25555475",
  },
  {
    id: 3,
    activeDate: "28-05-2024 12:26:44",
    activePair: "BNB/USDT",
    activePrice: "601.80000",
    activeSide: "Buy",
    activeOrder: "Limit",
    activeAmount: "0.3115648",
    activeTotal: "187.25555475",
  },
  {
    id: 4,
    activeDate: "28-05-2024 12:26:44",
    activePair: "BNB/USDT",
    activePrice: "601.80000",
    activeSide: "Sell",
    activeOrder: "Limit",
    activeAmount: "0.3115648",
    activeTotal: "187.25555475",
  },
  {
    id: 5,
    activeDate: "28-05-2024 12:26:44",
    activePair: "BNB/USDT",
    activePrice: "601.80000",
    activeSide: "Buy",
    activeOrder: "Limit",
    activeAmount: "0.3115648",
    activeTotal: "187.25555475",
  },
];

export const processOrderData = [
  {
    id: 1,
    sNo: "01",
    date: "Aug 26,2024 12:47PM",
    from: "xyz12345xy",
    message: "xyz12345xy has confirmed the payment for your 1 USDT sell order",
  },
  {
    id: 2,
    sNo: "02",
    date: "Aug 26,2024 12:47PM",
    from: "xyz12345xy",
    message: "xyz12345xy has confirmed the payment for your 1 USDT sell order",
  },
  {
    id: 3,
    sNo: "03",
    date: "Aug 26,2024 12:47PM",
    from: "xyz12345xy",
    message: "xyz12345xy has confirmed the payment for your 1 USDT sell order",
  },
  {
    id: 4,
    sNo: "04",
    date: "Aug 26,2024 12:47PM",
    from: "xyz12345xy",
    message: "xyz12345xy has confirmed the payment for your 1 USDT sell order",
  },
];

export const myOrderData = [
  {
    id: 1,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Buy",
    status: "Active",
  },
  {
    id: 2,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Sell",
    status: "Active",
  },
  {
    id: 3,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Buy",
    status: "Active",
  },
  {
    id: 4,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Sell",
    status: "Active",
  },
  {
    id: 5,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Buy",
    status: "Active",
  },
  {
    id: 6,
    optImg: "btc.png",
    optName: "BTC",
    optSub: "Bitcoin",
    date: "Aug 26,2024 12:47PM",
    quantity: "0.00000",
    price: "00.00",
    unit: "1.0000 - 10.0000 USDT",
    orderType: "Sell",
    status: "Active",
  },
];

export const walletViewData = [
  {
    id: 1,
    walletImg: "spot.png",
    walletTitle: "Spot",
    walletAmount: "00.000",
    ratio: "00.000",
  },
  {
    id: 2,
    walletImg: "spot.png",
    walletTitle: "Spot",
    walletAmount: "00.000",
    ratio: "00.000",
  },
  {
    id: 3,
    walletImg: "spot.png",
    walletTitle: "Spot",
    walletAmount: "00.000",
    ratio: "00.000",
  },
  {
    id: 4,
    walletImg: "spot.png",
    walletTitle: "Spot",
    walletAmount: "00.000",
    ratio: "00.000",
  },
];

export const leaderboard = [
  { id: 1, position: "01", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 2, position: "02", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 3, position: "03", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 4, position: "04", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 5, position: "05", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 6, position: "06", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 7, position: "07", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 8, position: "08", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 9, position: "09", userName: "User 1234", timeTaken: "00:00:00" },
  { id: 10, position: "10", userName: "User 1234", timeTaken: "00:00:00" },
];
