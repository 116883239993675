import React, { useState } from "react";
import Header from "./Header";
import { leaderboard } from "../utils/mockData";

const AirdropGame = () => {
  const [isStart, setIsStart] = useState(true);
  const [isSlideTop, setIsSlideTop] = useState(false);

  const handleClick = () => {
    setIsStart(false);
  };

  const handleSlideTop = () => {
    setIsSlideTop((prevSlideTop) => !prevSlideTop);
  };

  return (
    <>
      <section className="Non_fixed_nav airdrop-nav ">
        <Header />
      </section>

      <section className="airdrop-container">
        <div className="container">
          <div className="row airdrop-row">
            <div className="col-lg-12 my-3 py-4">
              {" "}
              {/* Start When you're ready */}
              {/* <div className="welcome-outer">
                <div className="wel-border">
                  <div className="wel-start-inner ">
                    <h4 className="welcome-title">Start When You’re Ready</h4>
                    <h5 className="welcome-content wel-content-width">
                      Type your answer and submit it as quickly as possible.
                    </h5>
                    <div className={`start-box ${!isStart ? "blurred" : ""}`}>
                      <h6>
                        If <span>2x+5=15,</span>
                      </h6>
                      <p>what is the value of x?</p>
                      <input
                        type="number"
                        placeholder="Enter the answer here"
                        className="start-input"
                      />
                      {isStart && (
                        <div className="wel-start-btn-wrap">
                          <button onClick={handleClick}>Start</button>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-3">
                      <button className="airdrop-step-btn">Submit</button>
                    </div>
                  </div>
                  <div className="stopwatch-container">
                    <img
                      src={require("../assets/stopwatch.png")}
                      alt="stopwatch"
                    />
                    <span>00:00:00</span>
                  </div>
                </div>
              </div> */}
              <div className="leaderboard-outer">
                <div className="leaderboard-inner">
                  <h5 className="welcome-title mb-4">Well Done!</h5>
                  <div className="success-img-wrapper">
                    <img
                      src={require("../assets/leaderboard-success.png")}
                      alt="leaderboard-success"
                    />
                  </div>
                  <p>
                    Your estimated time was
                    <span class="text-style-1 mx-1">00:00:00</span>
                  </p>

                  <div
                    className={`leaderboard-container ${
                      isSlideTop
                        ? "leaderboard-height"
                        : "leaderboard-container"
                    } `}
                  >
                    <div className="leaderboard-title">
                      <div>
                        <span>
                          <i class="fa-solid fa-chart-simple"></i>
                        </span>
                        <span className="mx-3 Leaderboard">Leaderboard</span>
                      </div>
                      <div onClick={handleSlideTop}>
                        {isSlideTop ? (
                          <span>
                            <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
                          </span>
                        ) : (
                          <span>
                            <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="leaderboard-main">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-start pad-lft-20">Position</th>
                            <th>Username</th>
                            <th className="text-end">Time Taken</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leaderboard.map((leaderDetails) => {
                            return (
                              <tr key={leaderDetails.id}>
                                <td className="text-start pad-lft-30 ">
                                  {leaderDetails.position}
                                </td>
                                <td>{leaderDetails.userName}</td>
                                <td className="text-yellow led-pad-ryt-20 text-end">
                                  {leaderDetails.timeTaken}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="leaderboard-footer">
                      <div className="leaderboard-footer-content">
                        You <span>(24)</span>
                      </div>
                      <div className="leaderboard-footer-content pad-ryt-30">
                        User <span>1234</span>
                      </div>
                      <div className="leaderboard-footer-content">
                        You <span>(24)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AirdropGame;
