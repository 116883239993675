import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Terms = () => {
  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>
        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  <h5 className="mb-5">Terms and Conditions</h5>
                  <h6>Effective Date: 31.8.2024</h6>
                  <div>
                    <ol>
                      {/* Introduction */}
                      <li className="policy-sub-heading">Introduction</li>
                      <p>
                        Welcome to Voltcrypt Networking Private Limited we By
                        accessing or using our trading platform , you agree to
                        be bound by these Terms and Conditions (the "Terms"). If
                        you do not agree to these Terms, please do not use our
                        Platform.
                      </p>
                      {/* definition */}
                      <li className="policy-sub-heading"> Definitions</li>
                      <ul>
                        <li>
                          <span className="policy-bold"> "User": </span>
                          Any individual or entity who accesses or uses the
                          Platform.
                        </li>
                        <li>
                          <span className="policy-bold"> "Services": </span>
                          The trading and other financial services provided by
                          Voltcrypt through the Platform.
                        </li>
                        <li>
                          <span className="policy-bold"> "Account": </span>
                          The personal account you create to access and use the
                          Platform.
                        </li>
                      </ul>
                      {/* user eligibility */}
                      <li className="policy-sub-heading">User Eligibility</li>
                      <p>
                        You must legally capable of entering into a contract to
                        use our Platform. By using the Platform, you represent
                        and warrant that you meet these requirements.
                      </p>
                      {/*  Account Registration*/}{" "}
                      <li className="policy-sub-heading">
                        Account Registration
                      </li>
                      <p>
                        To use our Services, you must create an Ac it. You agree
                        to provide accurate, complete, and current information
                        during registration and to update such information to
                        keep it accurate, complete, and current. You are
                        responsible for maintaining the confidentiality of your
                        Account credentials and for all activities that occur
                        under your Account.
                      </p>
                      {/*   Use of the Platform*/}{" "}
                      <li className="policy-sub-heading">
                        Use of the Platform
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold">Authorized Use: </span>
                          You agree to use the Platform only for lawful purposes
                          and in accordance with these Terms. You are prohibited
                          from using the Platform in any way that could damage,
                          disable, overburden, or impair the Platform.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Prohibited Activities:{" "}
                          </span>
                          You may not engage in fraudulent, deceptive, or
                          abusive practices, including but not limited to
                          manipulating or interfering with the trading process
                          or using the Platform to conduct illegal activities.
                        </li>
                      </ul>
                      {/* Trading and Transactions*/}{" "}
                      <li className="policy-sub-heading">
                        Trading and Transactions
                      </li>{" "}
                      <ul>
                        <li>
                          <span className="policy-bold">
                            Execution of Trades:{" "}
                          </span>
                          Trades executed through the Platform are subject to
                          our execution policies and are not guaranteed to be
                          executed at the price you see at the time of order
                          placement.
                        </li>
                        <li>
                          <span className="policy-bold">Fees: </span>
                          Fees for transactions and other services provided by
                          Voltcrypt are outlined on the Platform. You agree to
                          pay all applicable fees and charges.
                        </li>
                        <li>
                          <span className="policy-bold">
                            No Guarantee of Returns:{" "}
                          </span>
                          Trading in cryptocurrencies involves significant
                          risks. We do not guarantee any returns or profits from
                          trading activities.
                        </li>
                      </ul>{" "}
                      {/*  Risk Disclosure*/}{" "}
                      <li className="policy-sub-heading">Risk Disclosure</li>{" "}
                      <p>
                        Trading cryptocurrencies involves significant risks,
                        including but not limited to market volatility, loss of
                        investment, and potential regulatory changes. You should
                        carefully consider your financial situation and risk
                        tolerance before engaging in trading activities.
                      </p>
                      {/*  Privacy and Data Protection*/}{" "}
                      <li className="policy-sub-heading">
                        Privacy and Data Protection
                      </li>{" "}
                      <p>
                        We are committed to protecting your privacy. Our Privacy
                        Policy, which is incorporated into these Terms by
                        reference, explains how we collect, use, and protect
                        your personal information.
                      </p>
                      {/*  Intellectual Property */}
                      <li className="policy-sub-heading">
                        Intellectual Property
                      </li>{" "}
                      <p>
                        All content and materials on the Platform, including but
                        not limited to trademarks, logos, text, and software,
                        are the property of Voltcrypt or it censors and are
                        protected by intellectual property laws. You may not use
                        or reproduce any of this content without our express
                        written consent.
                      </p>
                      {/*  Limitation of Liability*/}
                      <li className="policy-sub-heading">
                        Limitation of Liability
                      </li>{" "}
                      <p>
                        To the fullest extent permitted by law, Voltcrypt shall
                        not be liable for any direct, indirect, incidental,
                        special, or consequential damages arising out of or in
                        connection with your use of the Platform or Services.
                      </p>
                      {/*  Indemnification*/}
                      <li className="policy-sub-heading">
                        Indemnification
                      </li>{" "}
                      <p>
                        You agree to indemnify and hold harmless Voltcrypt, its
                        affiliates, officers, directors, employees, and agents
                        from and against any claims, liabilities, damages,
                        losses, or expenses arising out of or related party to
                        your use of the Platform, violation these Terms, or
                        infringement of any rights of a third
                      </p>
                      {/*   Amendments*/}
                      <li className="policy-sub-heading">Amendments</li>{" "}
                      <p>
                        We reserve the right to amend these Terms at any time.
                        We will notify you of any significant changes by posting
                        the updated Terms on the Platform. Your continued use of
                        the Platform following any such changes constitutes your
                        acceptance of the new Terms.
                      </p>
                      {/*   Termination*/}
                      <li className="policy-sub-heading">Termination</li>{" "}
                      <p>
                        We may suspend or terminate your access to the Platform
                        if we believe you have violated these Terms or for any
                        other reason at our sole discretion. Upon termination,
                        you will no longer have access to your Account or any
                        associated information.
                      </p>
                      {/*   Governing Law*/}
                      <li className="policy-sub-heading">Governing Law</li>{" "}
                      <p>
                        These Terms are governed by and construed in accordance
                        with the laws of without regard to its conflict of law
                        principles.
                      </p>
                      {/*    Dispute Resolution*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Dispute Resolution
                      </li>{" "}
                      <p>
                        Any disputes arising out of or in connection with these
                        Terms shall be resolved through binding arbitration in
                        jurisdiction in accordance with the rules of The
                        arbitration proceedings shall be conducted in English.
                      </p>
                      {/*   Contact Information*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Contact Information
                      </li>{" "}
                      <p>
                        For any questions or concerns regarding these Terms,
                        please contact us at:
                      </p>
                      <div>
                        <span className="policy-sub-heading">Email : </span>{" "}
                        <span className="terms-email">
                          voltrixcrypt@gmail.com
                        </span>
                      </div>
                      <div className="terms-address">
                        <span className="policy-sub-heading">Address : </span>{" "}
                        <p className="policy-terms-ad">
                          VOLTCRYPT NETWORKING PRIVATE LIMITED. <br /> 45 - A,
                          Main Road, Arunachalapuram, <br /> Vickramasingapuram,{" "}
                          <br />
                          Ambasamudram, Tirunelveli- 627425, <br /> Tamil Nadu –
                          India.
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Terms;
