import React from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { Link } from "react-router-dom";
import HistoryListTable from "./HistoryListTable";

const WithdrawTable = () => {
  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="buy_head">
                    <div className="Buycrypto_title">History</div>
                    <ul className="history-lists">
                      <Link to="/depositHistory" className="history-links ">
                        Deposit
                      </Link>
                      <Link
                        to="/withdrawHistory"
                        className="history-links active"
                      >
                        Withdraw
                      </Link>
                      <Link to="/tradeHistory" className="history-links">
                        Trade
                      </Link>
                      <Link to="/loginHistory" className="history-links">
                        Login
                      </Link>
                      <Link to="/swapHistory" className="history-links">
                        Convert
                      </Link>
                      <Link to="/referralHistory" className="history-links">
                        Referral
                      </Link>
                      <Link to="/stakingHistory" className="history-links">
                        Staking
                      </Link>
                      <Link to="/orderHistory" className="history-links">
                        Order History
                      </Link>
                    </ul>
                    {/* <AssetListTable /> */}
                    <HistoryListTable />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WithdrawTable;
