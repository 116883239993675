import React, { useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

const AirdropTokens = () => {
  const stepperNum = [1, 2, 3, 4];

  const [stepCount, setStepCount] = useState(1);

  const handleSlide = () => {
    setStepCount((prevStep) =>
      prevStep < stepperNum.length ? prevStep + 1 : prevStep
    );
    console.log(stepCount);
  };

  const filteredStepperNum = stepperNum.slice(0, -1);
  console.log(filteredStepperNum);

  return (
    <>
      {" "}
      <section className="Non_fixed_nav airdrop-nav ">
        <Header />
      </section>{" "}
      <section className="airdrop-container">
        {stepCount <= 3 ? (
          <div className="container">
            <div className="row airdrop-row">
              <div className="col-lg-12 text-center my-3 py-4">
                <div>
                  {/* airdrop title */}
                  <div>
                    <h5 className="Play-and-Win-Your-Free-Tokens">
                      Play and Win Your
                      <span className="text-style-1"> Free Tokens!</span>
                    </h5>
                    <div className="free-token-content">
                      Answer the quiz, play the game, and earn exclusive tokens
                      to boost your crypto portfolio!
                    </div>
                  </div>

                  {/* stepper */}
                  <div className="step-container">
                    {/* {stepperNum.map((stepper, index) => {
                      return (
                        <div
                          key={stepper}
                          className={`stepper-contents ${
                            stepCount >= index + 1 && "active-step"
                          } ${stepCount > index && "active-line"}`}
                        >
                          <div className="stepper-count">{stepper}</div>
                        </div>
                      );
                    })} */}{" "}
                    {filteredStepperNum.map((stepper, index) => (
                      <div
                        key={stepper}
                        className={`stepper-contents ${
                          stepCount >= index + 1 ? "active-step" : ""
                        } ${stepCount > index ? "active-line" : ""}`}
                      >
                        <div className="stepper-count">{stepper}</div>
                      </div>
                    ))}
                  </div>

                  {/* box */}
                  <div className="outer-box">
                    <div className="Frame-15307">
                      <div className="earning-cards">
                        <h6 className="STEP-1">
                          {" "}
                          {stepCount === 3
                            ? "Are you ready ?"
                            : stepCount === 2
                            ? "STEP 2"
                            : "STEP 1"}
                        </h6>
                        <p className="Register-with-Voltrix-Crypt-to-join-the-airdrop pt-2">
                          {" "}
                          {stepCount === 3
                            ? "If you’re ready click here to begin the game!"
                            : stepCount === 2
                            ? " Register with Voltrix Crypt to join the airdrop!"
                            : " Register with Voltrix Crypt to join the airdrop!"}
                        </p>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="airdrop-step-btn"
                            onClick={handleSlide}
                          >
                            {stepCount === 3 ? (
                              <span>
                                Let's Go{" "}
                                <span className="mx-1 lets-arrow">
                                  <i class="fa-solid fa-arrow-right"></i>
                                </span>
                              </span>
                            ) : stepCount === 2 ? (
                              <Link className="text-clr">
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                  <span>
                                    <i class="fa-brands fa-telegram"></i>{" "}
                                  </span>
                                  <span className="">Telegram</span>
                                  <i class="fa-brands fa-telegram"></i>
                                </div>
                              </Link>
                            ) : (
                              <Link className="text-clr">
                                <span>Register</span>
                              </Link>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row airdrop-row">
              <div className="col-lg-12 my-3 py-4">
                {" "}
                {/* welcome box */}
                <div className="welcome-outer" data-aos="fade-up">
                  <div className="welcome-inner">
                    <div>
                      <h4 className="welcome-title">Welcome to the Game!</h4>
                      <h5 className="welcome-content ">Here’s how it works</h5>
                      <div className="wel-step-wrapper mar-top-42">
                        <h6 className="wel-step">Step 1</h6>
                        <div className="wel-step-content-wrap">
                          <h5 className="wel-sub-title">Start the Time</h5>
                          <p className="wel-sub-content">
                            Click the "Start" button to begin
                          </p>
                        </div>
                      </div>{" "}
                      <div className="wel-step-wrapper mar-top-42">
                        <h6 className="wel-step">Step 2</h6>
                        <div className="wel-step-content-wrap">
                          <h5 className="wel-sub-title">Answer Quickly</h5>
                          <p className="wel-sub-content">
                            Respond to the quiz question as fast as possible.
                            You only get one chance.
                          </p>
                        </div>
                      </div>
                      <div className="wel-step-wrapper mar-top-42">
                        <h6 className="wel-step">Step 3</h6>
                        <div className="wel-step-content-wrap">
                          <h5 className="wel-sub-title">Track Time</h5>
                          <p className="wel-sub-content">
                            Your response time will be recorded with a
                            stopwatch.
                          </p>
                        </div>
                      </div>
                      <div className="wel-step-wrapper mar-top-42">
                        <h6 className="wel-step">Step 4</h6>
                        <div className="wel-step-content-wrap">
                          <h5 className="wel-sub-title">Win the Reward</h5>
                          <p className="wel-sub-content">
                            The fastest correct answer wins the reward.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center my-4">
                        <Link to="/airdropgame">
                          <button
                            className="airdrop-step-btn"
                            onClick={handleSlide}
                          >
                            Next
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AirdropTokens;
