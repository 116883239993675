import React, { useEffect, useState } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
// import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import AssetListTable from "./AssetListTable";
import WalletViewTable from "./WalletViewTable";
import Select from "react-select";

const colourStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "#222327" : "#222327",
    color: isFocused ? "#ffc630" : "#fff",
    cursor: isDisabled ? "not-allowed" : "pointer",
    borderBottom: `1px solid ${isFocused ? "#ffc630" : "#17171a"}`,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
};

const Assets = () => {
  const cryptOptions = [
    { value: "BTC", label: "BTC" },
    { value: "INR", label: "INR" },
    { value: "USDT", label: "USDT" },
    { value: "USD", label: "USD" },
  ];

  useEffect(() => {
    getDefault("USDT");
  }, [0]);

  const [overallValue, setoverallValue] = useState(0);
  const [coinviewValue, setcoinviewValue] = useState({});
  const [walletviewValue, setwalletviewValue] = useState([]);

  const onSelect_currency = async (e, option) => {
    let selectedCurrency = e.label;
    getDefault(selectedCurrency);
  };

  const getDefault = async (data) => {
    try {
      var obj = {
        currency: data,
      };
      console.log(obj, "obj");
      var data = {
        apiUrl: apiService.balanceOverallBalance,
        payload: obj,
      };
      const response = await postMethod(data);
      console.log(response, "=-=-=response=-=-=-response-=-=-");
      if (response.status == true) {
        console.log(response.coinView, "coinViewcoinViewcoinView");
        console.log(response.overallValue, "responseresponseresponse");
        console.log(response.walletView, "walletViewwalletViewwalletView");
        setoverallValue(response.overallValue.value.toFixed(8));
        setcoinviewValue(response.coinView);
        setwalletviewValue(response.walletView);
      } else {
      }
    } catch (error) {}
  };

  const hide = async (e, ption) => {};

  const navigate = useNavigate();

  const depositNav = () => {
    navigate("/deposit");
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="asset_title">Assets</div>
                  <div className="col-lg-12">
                    {/* Estimated Value */}
                    <div className="esti-container">
                      <div className="esti-sub-container">
                        <div className="esti-left">
                          <span className="esti-title">Overall Balance</span>
                          <span className="esti-usdt">
                            {overallValue}
                            <span className="esti-span ">
                              {" "}
                              <Select
                                options={cryptOptions}
                                placeholder="USDT"
                                className="esti-span"
                                styles={colourStyles}
                                onChange={onSelect_currency}
                              />
                            </span>{" "}
                          </span>
                          <span className="esti-num">~ $0.00</span>
                        </div>
                        <div className="dash-bal-btns-wrapper ">
                          <button className="dash-bal-btn">Deposit</button>
                          <button className="dash-bal-btn">Withdrawal</button>
                          <button className="dash-bal-btn">Transfer</button>
                        </div>
                      </div>
                    </div>
                    {/* Assets list */}
                    <h5 className="identify-title">My Assets </h5>
                    {/* toggle buttons (email and phone)*/}
                    <div
                      class="nav nav-tabs asset-nav-tabs"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        class="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Wallet View
                      </button>
                      {/* <button
                        class="nav-link "
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Wallet View
                      </button> */}

                      <div className="asset-search">
                        {/* <div className="stake-search-container">
                          <input
                            type="text"
                            placeholder="Search"
                            className="stake-input"
                            // onChange={handleChange}
                          />
                          <i
                            class="fa-solid fa-magnifying-glass"
                            // onClick={() => searchWalletList()}
                          ></i>
                        </div> */}

                        <div>
                          <div class="hide-checkbox">
                            <input
                              id="custom-checkbox"
                              // checked={isChecked}
                              // onChange={handleTerms}
                              className="input-field regular_checkbox"
                              type="checkbox"
                              placeholder="Enter the referral ID (optional)"
                            />
                            <label htmlFor="custom-checkbox"></label>
                          </div>
                          <label
                            htmlFor="custom-checkbox"
                            className="asset-hide mx-2"
                            onClick={() => hide()}
                          >
                            Hide
                          </label>

                          <span className="asset-hide">0</span>
                          <span className="mx-2 asset-hide">Balance</span>
                        </div>
                      </div>
                    </div>

                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active mt-4"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                        tabindex="0"
                      >
                        {/* <AssetListTable /> */}
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead>
                              <tr className="stake-head ">
                                {/* <th>Asset</th> */}
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  Wallets{" "}
                                </th>
                                <th className="opt-nowrap table-action pad-left-23">
                                  Amount
                                </th>
                                {/* <th className="opt-nowrap txt-center pad-left-23"> Action </th> */}
                                <th className="opt-btn-flex table-action p-r-25">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="opt-percent  font_14 table_center_text pad-left-23">
                                  Spot
                                </td>
                                <td className="opt-term  font_14 table_center_text pad-left-23">
                                  {coinviewValue.spot}
                                </td>
                                <td className="opt-btn-flex table-action pad-left-23">
                                  <button className="asset-list-btn" onClick={depositNav}>
                                    Deposit
                                  </button>
                                </td>
                              </tr>

                              <tr>
                                <td className="opt-percent  font_14 table_center_text pad-left-23">
                                  Funding
                                </td>
                                <td className="opt-term  font_14 table_center_text pad-left-23">
                                  {coinviewValue.funding}
                                </td>
                                <td className="opt-btn-flex table-action pad-left-23">
                                  <button className="asset-list-btn" onClick={depositNav}>
                                    Deposit
                                  </button>
                                </td>
                              </tr>

                              <tr>
                                <td className="opt-percent  font_14 table_center_text pad-left-23">
                                  Staking
                                </td>
                                <td className="opt-term  font_14 table_center_text pad-left-23">
                                  {coinviewValue.staking}
                                </td>
                                <td className="opt-btn-flex table-action pad-left-23">
                                  <button className="asset-list-btn" onClick={depositNav}>
                                    Deposit
                                  </button>
                                </td>
                              </tr>

                              {/* <tr>
                                <td className="opt-percent  font_14 table_center_text pad-left-23">
                                  Margin
                                </td>
                                <td className="opt-term  font_14 table_center_text pad-left-23">
                                  {coinviewValue.margin}
                                </td>
                                <td className="opt-btn-flex table-action pad-left-23">
                                  <button className="asset-list-btn">
                                    Deposit
                                  </button>
                                </td>
                              </tr> */}

                            </tbody>
                          </table>

                          {/* <div className="paginate ">
        <span>
          <i class="fa-solid fa-chevron-left"></i>
        </span>
        <span className="paginate-one">1</span>
        <span>2</span>
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div> */}
                          <div className="pagination">
                            <Stack spacing={2}>
                              <Pagination
                                // count={Math.ceil(total / recordPerPage)}
                                // page={currentPage}
                                // onChange={handlePageChange}
                                size="small"
                                sx={{
                                  "& .MuiPaginationItem-root": {
                                    color: "#fff", // Default text color for pagination items
                                    // backgroundColor: "#2D1E23",
                                    // "&:hover": {
                                    //   backgroundColor: "#453a1f",
                                    //   color: "#ffc630",
                                    // },
                                  },
                                  "& .Mui-selected": {
                                    backgroundColor: "#ffc630 !important", // Background color for selected item
                                    color: "#000", // Text color for selected item
                                    "&:hover": {
                                      backgroundColor: "#ffc630",
                                      color: "#000",
                                    },
                                  },
                                  "& .MuiPaginationItem-ellipsis": {
                                    color: "#fff", // Color for ellipsis
                                  },
                                  "& .MuiPaginationItem-icon": {
                                    color: "#fff", // Color for icon (if present)
                                  },
                                }}
                                // renderItem={(item) => (
                                //   <PaginationItem
                                //     slots={{
                                //       previous: ArrowBackIcon,
                                //       next: ArrowForwardIcon,
                                //     }}
                                //     {...item}
                                //   />
                                // )}
                              />
                            </Stack>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade mt-4"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                        tabindex="0"
                      >
                        {/* <WalletViewTable /> */}
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead>
                              <tr className="stake-head ">
                                <th>Wallet</th>
                                <th className="opt-nowrap txt-center pad-left-23 pad-l-100">
                                  Amount
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23  pad-l-100">
                                  Ratio
                                </th>
                                <th className="opt-btn-flex table-action p-r-25">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            {/* <tbody>
          {walletViewData.map((options) => {
            return (
              <tr key={options.id}>
                <td className="table-flex">
                  <img src={require(`../assets/${options.walletImg}`)} alt="" />
                  <div className="table-opt-name">
                    <h4 className="opt-name  font_14">{options.walletTitle}</h4>
                  </div>
                </td>

                <td className="opt-term  font_14 table_center_text pad-left-23 pad-l-100">
                  <div>{options.walletAmount}</div>
                  <div className="txt-lgt ">$0.00</div>
                </td>
                <td className="opt-term  font_14 table_center_text pad-left-23 pad-l-100">
                  {options.ratio}
                </td>
                <td className="opt-btn-flex table-action pad-left-23">
                  <button className="asset-list-btn">Trade</button>
                </td>
              </tr>
            );
          })}
        </tbody> */}
                          </table>

                          {/* <div className="pagination">
        <Stack spacing={2}>
          <Pagination
            size="small"
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#fff",
              },
              "& .Mui-selected": {
                backgroundColor: "#ffc630 !important", 
                color: "#000", 
                "&:hover": {
                  backgroundColor: "#ffc630",
                  color: "#000",
                },
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#fff",
              },
              "& .MuiPaginationItem-icon": {
                color: "#fff", 
              },
            }}
          />
        </Stack>
      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Assets;
