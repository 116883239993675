import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Privacy = () => {
  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  <h5 className="mb-5">Privacy Policy</h5>
                  <h6>Effective Date : 31.8.2024</h6>
                  <div>
                    <ol>
                      {/* Introduction */}
                      <li className="policy-sub-heading">Introduction</li>
                      <p>
                        Welcome to Voltcrypt Networking Private Limited we .
                        This Privacy Policy explains how we collect, use,
                        disclose, and safeguard your personal information when
                        you use our trading platform . By using our Platform,
                        you consent to the practices described in this policy.
                      </p>{" "}
                      {/*  Information We Collect */}
                      <li className="policy-sub-heading">
                        {" "}
                        Information We Collect
                      </li>
                      <p>
                        We may collect and process the following types of
                        personal information:
                      </p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            Contact Information:{" "}
                          </span>
                          Name, email address, phone number, and physical
                          address.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Account Information:{" "}
                          </span>
                          Username, password, and other details related to your
                          account and registration.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Financial Information:{" "}
                          </span>
                          Payment details, transaction history, and trading
                          data.
                        </li>
                        <li>
                          <span className="policy-bold">Usage Data: </span>
                          Information about how you use the Platform, including
                          IP address, browser type, operating system, and usage
                          patterns.
                        </li>
                        <li>
                          <span className="policy-bold">Technical Data: </span>
                          Device information, log files, and other technical
                          data related to your interactions with the Platform.
                        </li>
                      </ul>{" "}
                      {/* How We Use Your Information */}
                      <li className="policy-sub-heading">
                        How We Use Your Information
                      </li>
                      <p>
                        We use your personal information for the following
                        purposes:
                      </p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            To Provide and Manage Services:{" "}
                          </span>
                          To process transactions, execute trades, manage your
                          account, and provide customer support.
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            To Improve Our Platform:{" "}
                          </span>
                          To analyze usage patterns, enhance functionality, and
                          develop new features.
                        </li>
                        <li>
                          <span className="policy-bold">
                            To Communicate with You:{" "}
                          </span>
                          To send you updates, newsletters, and promotional
                          materials (you may opt-out at any time).
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            To Comply with Legal Obligations:{" "}
                          </span>
                          To adhere to regulatory requirements and legal
                          obligations.
                        </li>
                      </ul>
                      {/* How We Share Your Information*/}
                      <li className="policy-sub-heading">
                        How We Share Your Information
                      </li>
                      <p>We may share your personal information with:</p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            Service Providers:{" "}
                          </span>
                          Third-party vendors who perform services on our
                          behalf, such as payment processors and IT support.
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            Regulatory Authorities:{" "}
                          </span>
                          To comply with legal requirements or respond to lawful
                          requests and legal processes.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Business Transfers:{" "}
                          </span>
                          In connection with a merger, acquisition, or sale of
                          assets, where your information may be transferred as
                          part of the transaction.
                        </li>
                        <li>
                          <span className="policy-bold"> Affiliates: </span>
                          Companies within our corporation group for the
                          purposes described in this policy.
                        </li>
                      </ul>
                      {/*Data Security*/}
                      <li className="policy-sub-heading">Data Security</li>
                      <p>
                        We implement reasonable technical and organizational
                        measures to protect your personal information from
                        unauthorized access, disclosure, alteration, or
                        destruction. However, no method of transmission over the
                        internet or electronic storage is 100% secure, and we
                        cannot guarantee absolute security.
                      </p>
                      {/* Your Rights*/}
                      <li className="policy-sub-heading"> Your Rights</li>
                      <p>
                        Depending on your jurisdiction, you may have the
                        following rights regarding your personal information:
                      </p>{" "}
                      <ul>
                        <li>
                          <span className="policy-bold">Access: </span>
                          To request a copy of your personal information that we
                          hold.
                        </li>
                        <li>
                          <span className="policy-bold">Correction: </span>
                          To request correction of inaccurate or incomplete
                          information.
                        </li>
                        <li>
                          <span className="policy-bold">Deletion: </span>
                          To request the deletion of your personal information,
                          subject to certain legal limitations.
                        </li>
                        <li>
                          <span className="policy-bold">Objection: </span>
                          To object to or restrict the processing of your
                          personal information in certain circumstances.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Data Portability:{" "}
                          </span>
                          To request a copy of your data in a structured,
                          commonly used, and machine-readable form.
                        </li>
                      </ul>
                      <p>
                        {" "}
                        To exercise these rights, please contact us at
                        [voltrixcrypt@gmail.com].
                      </p>
                      {/*  Cookies and Tracking Technologies*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Cookies and Tracking Technologies
                      </li>
                      <p>
                        We use cookies and similar tracking technologies to
                        collect information about your interactions with the
                        Platform. You can manage your cookie preferences through
                        your browser settings.
                      </p>
                      {/*  Children's Privacy*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Children's Privacy
                      </li>
                      <p>
                        Our Platform is not intended for individuals under the
                        age of 18. We do not knowingly collect personal
                        information from children under 18. If we become aware
                        that we have collected such information, we will take
                        steps to delete it.
                      </p>
                      {/*  Changes to This Privacy Policy*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Changes to This Privacy Policy
                      </li>
                      <p>
                        We may update this Privacy Policy from time to time. Any
                        changes will be posted on this page with an updated
                        effective date. We encourage you to review this policy
                        periodically to stay informed about how we are
                        protecting your information.
                      </p>{" "}
                      {/*   Contact Information*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Contact Information
                      </li>{" "}
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy or our data practices, please contact us at :
                      </p>
                      <div>
                        <span className="policy-sub-heading">Email: </span>{" "}
                        <span className="terms-email">
                          voltrixcrypt@gmail.com
                        </span>
                      </div>
                      <div className="terms-address">
                        <span className="policy-sub-heading">Address: </span>{" "}
                        <p className="policy-terms-ad">
                          VOLTCRYPT NETWORKING PRIVATE LIMITED. <br /> 45 - A,
                          Main Road, Arunachalapuram, <br /> Vickramasingapuram,{" "}
                          <br />
                          Ambasamudram, Tirunelveli- 627425, <br /> Tamil Nadu –
                          India.
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Privacy;
