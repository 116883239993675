import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const ReturnPolicy = () => {
  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>
        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  <h5 className="mb-5">Return Policy</h5>
                  <h6>Effective Date : 31.8.2024</h6>
                  <div>
                    <ol>
                      {/* Introduction */}
                      <li className="policy-sub-heading">Introduction</li>
                      <p>
                        Welcome to Voltcrypt Networking Private Limited we. This
                        Return Policy outlines the conditions under which
                        refunds or returns may be requested for services
                        provided through our trading platform . By using our
                        Platform, you agree to the terms outlined in this
                        policy.
                      </p>
                      {/*Scope of Policy*/}
                      <li className="policy-sub-heading"> Scope of Policy</li>
                      <p>This Return Policy applies to the following:</p>
                      <ul>
                        <li>
                          <span className="policy-bold"> Trading Fees: </span>
                          Fees charged for executing trades on the Platform.
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            Subscription Fees:{" "}
                          </span>
                          Fees for accessing premium features or services.
                        </li>
                      </ul>
                      {/* Refunds for Trading Fees*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Refunds for Trading Fees
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold"> No Refunds: </span>
                          Once a trade is executed, trading fees are
                          non-refundable. This includes cases where trades
                          result in losses or if the trade was executed at an
                          unexpected price due to market volatility.
                        </li>
                      </ul>
                      {/*Refunds for Subscription Fees*/}
                      <li className="policy-sub-heading">
                        Refunds for Subscription Fees
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold"> Trial Period: </span>
                          If you are subscribed to a service with a trial
                          period, you may cancel within the trial period for a
                          full refund. Ensure that you cancel before the trial
                          period ends to avoid being charged for the subsequent
                          billing cycle.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Monthly/Annual Subscriptions:{" "}
                          </span>
                          For subscription services billed on a monthly or
                          annual basis:
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            Monthly Subscriptions:{" "}
                          </span>
                          You may request a refund for the current billing
                          period if you cancel your subscription within 7 days
                          of the charge. Refunds will not be issued for previous
                          billing periods or if the refund request is made after
                          the 7-day window.
                        </li>
                      </ul>{" "}
                      {/*Incorrect Transactions*/}
                      <li className="policy-sub-heading">
                        Incorrect Transactions
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            Error Resolution:{" "}
                          </span>
                          If you believe there has been an error with a
                          transaction, such as an incorrect trade execution or
                          charge, you must contact our customer support team
                          within 48 hours of the transaction. We will review the
                          issue and, if appropriate, adjust or refund the
                          incorrect charges.
                        </li>
                      </ul>{" "}
                      {/* Cancellation and Refund Requests*/}
                      <li className="policy-sub-heading">
                        Cancellation and Refund Requests
                      </li>
                      <h6>To request a refund or cancellation: </h6>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            Contact Customer Support:{" "}
                          </span>
                          Submit a request through our customer support portal
                          or email us at [voltrixcrypt@gmail.com] with details
                          of your request and any relevant transaction
                          information.
                        </li>
                        <li>
                          <span className="policy-bold">
                            Review and Processing:{" "}
                          </span>
                          We will review your request and determine eligibility
                          based on this policy. Processing times may vary
                          depending on the nature of the request and volume of
                          inquiries.
                        </li>
                      </ul>{" "}
                      {/*  Account Termination*/}
                      <li className="policy-sub-heading">
                        Account Termination
                      </li>
                      <p>
                        If you choose to terminate your account, you will not be
                        eligible for a refund of any fees paid unless otherwise
                        specified in this policy, Any balances or credits in
                        your account will be forfeited upon termination.
                      </p>
                      {/* Changes to the Return Policy*/}
                      <li className="policy-sub-heading">
                        Changes to the Return Policy
                      </li>
                      <p>
                        We reserve the right to update or modify this Return
                        Policy at any time. Any changes will be effective
                        immediately upon posting on the Platform. Your continued
                        use of the Platform following any such changes
                        constitutes your acceptance of the revised policy,
                      </p>
                      {/*   Contact Information*/}
                      <li className="policy-sub-heading">
                        {" "}
                        Contact Information
                      </li>{" "}
                      <p>
                        For any questions or concerns regarding this Return
                        Policy, please contact us at:
                      </p>
                      <div>
                        <span className="policy-sub-heading">Email: </span>{" "}
                        <span className="terms-email">
                          voltrixcrypt@gmail.com
                        </span>
                      </div>
                      <div className="terms-address">
                        <span className="policy-sub-heading">Address: </span>{" "}
                        <p className="policy-terms-ad">
                          VOLTCRYPT NETWORKING PRIVATE LIMITED. <br /> 45 - A,
                          Main Road, Arunachalapuram, <br /> Vickramasingapuram,{" "}
                          <br />
                          Ambasamudram, Tirunelveli- 627425, <br /> Tamil Nadu –
                          India.
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default ReturnPolicy;
