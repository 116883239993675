import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Bars } from "react-loader-spinner";

const Security = () => {
  useEffect(() => {
    getProfile();
    // FindData();
    // fetchTfaData();
  }, []);

  const [profileData, setprofileData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [antiStatus, setAntiStatus] = useState("");
  const navigate = useNavigate();
  const [tfaDetails, setTfaDetails] = useState("");
  const [changeCode, setchangeCode] = useState(false);

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status == true) {
        setprofileData(resp.Message);
        setTfaDetails(resp.Message.tfastatus);
        setAntiStatus(resp.Message.AntiphisingStatus);
        sessionStorage.setItem("tfa_status", resp.data.tfastatus);
        // localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };

  // const FindData = async () => {
  //   var data = {
  //     apiUrl: apiService.findDetails,
  //   };
  //   setSiteLoader(true);

  //   var responce = await postMethod(data);
  //   setSiteLoader(false);

  //   if (responce.data != null) {
  //     if (responce.data.APcode != "") {
  //       var str = responce.data.APcode;
  //       setAPcodes(str);
  //       setchangeCode(true);
  //     } else {
  //       setchangeCode(false);
  //     }
  //   } else {
  //     setchangeCode(false);
  //   }
  //   // console.log(changeCode,"changecokowendoiwn");
  // };

  // const fetchTfaData = async () => {
  //   try {
  //     var data = {
  //       apiUrl: apiService.getTfaDetials,
  //     };
  //     setSiteLoader(true);
  //     var resp = await getMethod(data);
  //     setSiteLoader(false);
  //     let tfastatus = localStorage.getItem("tfa_status");
  //     setTfaDetails(tfastatus);
  //     console.log(tfastatus,"tfastatus=-=-=-=-");
  //   } catch (error) {}
  // };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 5);
    return `${firstFive}***@${domainPart}`;
  };

  const obfuscateMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return "";
    const firstFive = mobileNumber.slice(0, 5);
    const lastOne = mobileNumber.slice(-1);
    return `${firstFive}****${lastOne}`;
  };

  return (
    <>
      <section>
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <Side_bar />
              </div>

              <div className="col-lg-10">
                <div className="dashboard_content border_none">
                  <div className="security_content">
                    <h3>Security</h3>
                  </div>
                  <div className="security_settings">
                    <div>
                      Your security settings is{" "}
                      {profileData.tfastatus == 0 &&
                      profileData.AntiphisingStatus == 0 ? (
                        <span className="low-clr">Low</span>
                      ) : profileData.tfastatus == 0 ||
                        profileData.AntiphisingStatus == 0 ? (
                        <span className="mid-clr">Medium</span>
                      ) : (
                        <span className="high-clr">High</span>
                      )}
                    </div>
                    <div className="low_line">
                      {profileData.tfastatus == 0 &&
                      profileData.AntiphisingStatus == 0 ? (
                        <>
                          <p className="verify_fail">
                            <i class="ri-shield-keyhole-line"></i> Low
                          </p>
                          <img
                            src={require("../assets/low_line.png")}
                            className="secu_img"
                            alt="Low security"
                          />
                        </>
                      ) : profileData.tfastatus == 0 ||
                        profileData.AntiphisingStatus == 0 ? (
                        <>
                          <p className="verify_medium">
                            <i class="ri-shield-keyhole-line"></i> Medium
                          </p>
                          <img
                            src={require("../assets/mid_line.png")}
                            className="secu_img"
                            alt="Medium security"
                          />
                        </>
                      ) : (
                        <>
                          <p className="verify_success">
                            <i class="ri-shield-keyhole-line"></i> High
                          </p>
                          <img
                            src={require("../assets/high_line.png")}
                            className="secu_img"
                            alt="High security"
                          />
                        </>
                      )}
                      {/* <p>
                      {" "}
                      <i class="ri-shield-keyhole-line"></i> Low
                    </p>
                    <img src={require("../assets/low_line.png")} /> */}
                    </div>
                  </div>
                  <div className="two_fa_heading">
                    Two-Factor Aunthentication (2FA)
                  </div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/icons/email_icon.webp")} />

                      <div className="">
                        <h3>Email Verification</h3>
                        <p>
                          It is used for login, withdrawals, password updates,
                          and security settings
                        </p>
                      </div>
                    </div>
                    <div className="email_id_text">
                      <i class="ri-checkbox-circle-fill"></i>{" "}
                      <span> {obfuscateEmail(profileData.email)}</span>
                    </div>
                  </div>
                  {/* <div className="security_email_content">
                  <div className="security_email_item">
                    <img src={require("../assets/phone_icon.png")} />

                    <div className="">
                      <h3>Phone No Verification</h3>
                      <p>
                        To safeguard your transactions and account, use your
                        phone number.
                      </p>
                    </div>
                  </div>
                  <div className="email_id_text">
                    {" "}
                    {profileData.mobileNumber == "" ? (
                     <span>Not Verified</span>
                    ) : (<>
                      <i class="ri-checkbox-circle-fill"></i>
                      <span>{obfuscateMobileNumber(profileData.mobileNumber)}</span>
                      </>
                    )}
                   
                  </div>
                </div> */}
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img src={require("../assets/icons/2fa.webp")} />

                      <div className="">
                        <h3>2FA</h3>
                        <p>Use 2FA to protect your account and transactions.</p>
                      </div>
                    </div>
                    {tfaDetails == 0 ? (
                      <div className="disabled_text">
                        <p>
                          <i class="ri-close-circle-fill"></i>
                          <span className="text-lightGrey"> Disabled </span>
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <p>
                          <i
                            class="ri-checkbox-circle-fill"
                            style={{ color: "#22b477" }}
                          ></i>
                          <span className="text-lightGrey">Enabled </span>
                        </p>
                      </div>
                    )}
                    {tfaDetails == 0 ? (
                      <div className="enable_btn">
                        <Link to="/enabletfa">
                          <button>Enable</button>
                        </Link>
                      </div>
                    ) : (
                      <div className="disable_btn">
                        <Link to="/enabletfa">
                          <button>Disable</button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="two_fa_heading">Advanced Security</div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img
                        src={require("../assets/icons/anti_phishing_icon.webp")}
                      />

                      <div className="">
                        <h3>Anti-phishing Code</h3>
                        <p>
                          Displayed in emails from VOLTRIX to safeguard against
                          <br />
                          phishing attempts.
                        </p>
                      </div>
                    </div>
                    {antiStatus == 0 ? (
                      <div className="">
                        <p>
                          <i class="ri-close-circle-fill"></i>{" "}
                          <span className="text-lightGrey">Disabled</span>
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <p>
                          <i
                            class="ri-checkbox-circle-fill"
                            style={{ color: "#22b477" }}
                          ></i>{" "}
                          <span className="text-lightGrey">Enabled</span>
                        </p>
                      </div>
                    )}
                    {antiStatus == 0 ? (
                      <div className="enable_btn">
                        <Link to="/anti-phishing">
                          <button>Enable</button>
                        </Link>
                      </div>
                    ) : (
                      <div className="disable_btn">
                        <Link to="/anti-phishing">
                          <button>Change</button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="two_fa_heading">Password Management</div>
                  <div className="security_email_content">
                    <div className="security_email_item">
                      <img
                        src={require("../assets/icons/login_password_icon.webp")}
                      />

                      <div>
                        <h3>Login Password</h3>
                        <p>Login password is used to log in to your account.</p>
                      </div>
                    </div>

                    <div className="enable_btn">
                      <Link to="/security_change">
                        {" "}
                        <button>Change</button>
                      </Link>
                    </div>
                  </div>

                  {/* <div className="two_fa_heading">Account Management</div>
                <div className="security_email_content">
                  <div className="security_email_item">
                    <img src={require("../assets/delete_icon.png")} />

                    <div className="">
                      <h3>Delete Account</h3>
                      <p>
                        Note: All the related data will be deleted and cannot be
                        recovered after the deletion.
                      </p>
                    </div>
                  </div>

                  <div className="delete_button">
                    <button>Delete</button>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Security;
